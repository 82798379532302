import $ from "jquery";
import slick from "slick-carousel";

$(document).ready(function () {
    const carousel = $(".carousel__extended");

    carousel.slick({
        centerMode: true,
        centerPadding: "33%",
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        initialSlide: Math.max((window.TESTRESULT || 1) - 2, 0),
        speed: 400,
        dots: true,
        arrows: true,
        appendArrows: $(".carousel__arrows"),
        prevArrow:
            '<button id="prev" type="button" class="btn btn-prev"><i><svg class="svg-icon"><use xlink:href="#arrow_left"></use></svg></i></button>',
        nextArrow:
            '<button id="next" type="button" class="btn btn-next"><i><svg class="svg-icon"><use xlink:href="#arrow_right"></use></svg></i></button>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerMode: true,
                    centerPadding: "20%",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    centerPadding: "55px",
                    slidesToShow: 1,
                },
            },
        ],
    });

    carousel.on("afterChange", (event, slick, currentSlide) => {
        const newPath = `/extended-result/${window.customerId}/${
            window.lastSkill
        }/${currentSlide + 2}${location.search}#carousel`;

        history.replaceState(undefined, "", newPath);

        const result = parseInt(
            document
                .querySelector(`[data-carousel-index="${currentSlide}"]`)
                ?.getAttribute("data-carousel-n") || 1,
            10
        );

        document.querySelectorAll("[data-t-hidden]").forEach((element) => {
            const resultNumbersToHide = JSON.parse(
                element.getAttribute("data-t-hidden") || "[]"
            );

            if (resultNumbersToHide.includes(result)) {
                element.classList.add("hidden");
            } else {
                element.classList.remove("hidden");
            }
        });

        document.querySelectorAll("[data-t]").forEach((element) => {
            const transKey = element
                .getAttribute("data-t")
                .replace(":result:", result);

            element.textContent =
                transKey in window.appTrans
                    ? window.appTrans[transKey]
                    : transKey;
        });

        ["src", "data-result", "href"].forEach((attrName) => {
            document
                .querySelectorAll(`[data-t-${attrName}]:not(.hidden)`)
                .forEach((element) => {
                    if (element.closest(".hidden")) {
                        return;
                    }

                    const transKey = element
                        .getAttribute(`data-t-${attrName}`)
                        .replace(":result:", result);

                    element.setAttribute(
                        attrName,
                        transKey in window.appTrans
                            ? window.appTrans[transKey]
                            : transKey
                    );
                });
        });
    });
});
// $(window).resize(function () {
//     const carousel = $(".carousel__extended");
//     if (carousel.get(0)) {
//         carousel[0].slick.refresh();
//     }
// });
