import imagesLoaded from 'imagesloaded';
import './components/roundSlider';
import './components/carousel';
// import  './components/Test2';
// import  './components/Test3';
import './components/result-form';

// all pages
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
  isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';


window.imagesLoaded = imagesLoaded;

imagesLoaded(document.body, () => {
  console.log('test');
  document.body.classList.add('load');
});

window.nextQuestion = function(item){
  setTimeout(() => {
      const sibling = item.nextElementSibling;
      if(!!sibling){
          if(sibling.hidden){
              item.hidden = true;
              sibling.hidden = false;
          }
      }
  }, 400);
}


const langSwitch = document.querySelectorAll('.lang-switch');
langSwitch.forEach(el => {
    if(el.classList.contains('on')){
        el.parentElement.classList.add('is-active');
    }
});