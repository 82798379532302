import $ from "jquery";
import roundSlider from "round-slider";
import textFit from "textfit";
import slick from "slick-carousel";

/// services f-ns
Number.isInteger =
    Number.isInteger ||
    function (value) {
        return (
            typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value
        );
    };
function localGet() {
    return (localStorage.skills && JSON.parse(localStorage.skills)) || [];
}

/// quiz
$(function () {
    const pageQuiz = $("#quizPage"),
        pageResult = $(".result-page");

    if (pageQuiz) {
        const slide = $(".compare__item"),
            inputSlider = $('input[type="range"]', slide),
            btnPrev = $("#quizBack"),
            btnNext = $("#quizNext");

        let DATA = [];
        // {
        // id: '', question number
        // answer: '', 0 / 1 / 2 / 3 / 4 - answer code
        // category: 'skill_1' / 'skill_2' / 'skill_3' / 'skill_4' / 'skill_5' - answer category
        // }

        // 0 - variant "a" - 100%
        // 1 - variant "a" -  50%
        // 2 - no variant ?
        // 3 - variant "b" -  50%
        // 4 - variant "b" - 100%

        function refreshCssVariables() {
            document.documentElement.style.setProperty(
                "--roundSize",
                $(".layout__main > .container").width() + "px"
            );
        }
        refreshCssVariables();

        (function prevStep() {
            btnPrev.on("click", function (e) {
                e.preventDefault();
                if($('.slick-slide.slick-current').attr('data-slick-index') == 0){
                    // history.back();
                    window.location = '/tutorial';
                } else {
                    pageQuiz.slick("slickPrev");
                }
            });
        })();

        (function nextStep() {
            btnNext.on("click", function (e) {
                e.preventDefault();
                pageQuiz.slick("slickNext");
                if ($(this).hasClass("is-last")) {
                    // put last answer in data
                    const lastQuestion = {
                        id: pageQuiz.slick("slickCurrentSlide"),
                        answer: $(
                            `.slick-slide[data-slick-index="${pageQuiz.slick(
                                "slickCurrentSlide"
                            )}"] .rs-control input`
                        ).val(),
                        category: $(
                            `.slick-slide[data-slick-index="${pageQuiz.slick(
                                "slickCurrentSlide"
                            )}"] .compare__item`
                        ).attr("data-category"),
                        n: parseInt(
                            $(
                                `.slick-slide[data-slick-index="${pageQuiz.slick(
                                    "slickCurrentSlide"
                                )}"] .compare__item`
                            ).attr("data-n"),
                            10
                        ),
                    };
                    // console.log(lastQuestion);
                    refreshData(lastQuestion);
                    sendResult(DATA);
                }
            });
        })();

        function sendResult(data) {
            const answers = data.map((item) => ({
                ...item,
                answer: parseInt(item.answer, 10),
            }));
            $.ajax({
                url: "/result",
                type: "POST",
                data: JSON.stringify({ answers }),
                contentType: "application/json",
                dataType: "json",
            }).then(
                (response) => {
                    const { result_uuid } = response.data;

                    localStorage.setItem("answers", JSON.stringify(answers));
                    localSet(response.data);

                    window.location.href = `/result/${result_uuid}${location.search}`;
                },
                (err) => {
                    console.log(err);
                }
            );
        }

        function localSet(categories) {
            localStorage.setItem("skills", JSON.stringify(categories));
        }

        function refreshData(slideObj) {
            if (!DATA.some((e) => e.id === slideObj.id)) {
                DATA.push(slideObj);
            } else {
                DATA.find((e) => e.id === slideObj.id).answer = slideObj.answer;
            }
            console.log(DATA);
        }

        function animImgs(elemId, state) {
            // 5 states:
            // 4: img1 w137.5%, img2 w50% h50% blur 3px     // X o o o o
            // 3: img1 w120%, img2 w75% h75% blur 1px       // o X o o o
            // 2: img1 w100%, img2 w100% h100% blur 0       // o o X o o
            // 1: img1 w75% h75% blur 1px, img2 w120%       // o o o X o
            // 0: img1 w50% h50% blur 3px, img2 w137.5%     // o o o o X
            const parent = $(`input#${elemId}`)
                .closest(".compare__item")
                .find(".compare__interface")[0];
            // console.log(parent);
            if (!!parent && Number.isInteger(state)) {
                switch (state) {
                    case 0:
                        parent.className =
                            "compare__interface compare-imgsSize_25-75";
                        break;
                    case 1:
                        parent.className =
                            "compare__interface compare-imgsSize_33-66";
                        break;
                    case 3:
                        parent.className =
                            "compare__interface compare-imgsSize_66-33";
                        break;
                    case 4:
                        parent.className =
                            "compare__interface compare-imgsSize_75-25";
                        break;
                    default:
                        parent.className = "compare__interface";
                        break;
                }
            }
        }

        function setStatesToDefault() {
            const parent = $(".slick-slide.slick-active .compare__interface");
            if (parent.length > 0) {
                parent[0].className = "compare__interface";
            }
        }

        function textHandler() {
            const textContent = $(".compare__text-content");
            textFit(textContent, {
                multiLine: true,
                reProcess: true,
                // widthOnly: true
            });
        }

        function createRoundSlider() {
            const options = {
                min: 0,
                max: 4,
                step: 1,
                val: 2,
                radius: $(".layout__main > .container").width() / 2,
                width: $(".layout__main > .container").width() * 0.053,
                handleSize: $(".layout__main > .container").width() * 0.13,
            };
            // console.log(options);

            inputSlider.roundSlider({
                sliderType: "min-range",
                circleShape: "custom-quarter",
                min: options.min,
                max: options.max,
                step: options.step,
                value: options.val,
                startAngle: 225,
                editableTooltip: false,
                showTooltip: false,
                radius: options.radius,
                width: options.width,
                handleShape: "dot",
                animation: false,
                handleSize: options.handleSize,
                // svgMode: true,
                // borderColor: '#fff',
                // borderWidth: 3,
                update: function (e) {
                    // console.log(e.value);
                    // console.log(e.id);
                    animImgs(e.id, e.value);
                },
                // tooltipFormat: "tooltipVal1"
            });
        }

        function startGlobalQuizSlider() {
            pageQuiz
                .slick({
                    accessibility: false,
                    autoplay: false,
                    arrows: false,
                    dots: false,
                    draggable: false,
                    fade: true,
                    infinite: false,
                    swipe: false,
                    touchMove: false,
                })
                .on(
                    "beforeChange",
                    function (event, slick, currentSlide, nextSlide) {
                        const slideObj = {
                            id: currentSlide,
                            answer: $(
                                `.slick-slide[data-slick-index="${currentSlide}"] .rs-control input`
                            ).val(),
                            category: $(
                                `.slick-slide[data-slick-index="${currentSlide}"] .compare__item`
                            ).attr("data-category"),
                        };
                        refreshData(slideObj);
                    }
                )
                .on("afterChange", function (event, slick, currentSlide) {
                    if (currentSlide === slick.slideCount - 1) {
                        $("#quizNext").addClass("is-last");
                    } else {
                        $("#quizNext").removeClass("is-last");
                    }
                });
        }

        textHandler();
        createRoundSlider();
        startGlobalQuizSlider();

        $(window).on("resize", function () {
            textHandler();
            createRoundSlider();
            setStatesToDefault();
            refreshCssVariables();
        });
    }

    if (pageResult) {
        $(function () {
            const skills = localGet(),
                form = $("#form");

            if (skills.length > 0) {
                $.each(skills, function (i, value) {
                    const input = $("<input>")
                        .attr("type", "hidden")
                        .attr("name", value)
                        .val(true);
                    $(form).append($(input));
                });
            }
        });
    }
});
