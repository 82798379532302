import $ from "jquery";

const scrollBtn = document.querySelector(".js-scroll-down");
const main = document.querySelector(".result-form__main");

if (scrollBtn) {
    scrollBtn.addEventListener("click", () =>
        main.scrollIntoView({ block: "center", behavior: "smooth" })
    );
}

// Checkboxes checked
const cbx1 = document.getElementById("agree");

function checkParam() {
    const btn = document.getElementById("submit");
    if (cbx1.checked) {
        btn.removeAttribute("disabled");
    } else {
        btn.setAttribute("disabled", "");
    }
}
if (cbx1) {
    cbx1.addEventListener("change", () => {
        checkParam();
    });
}

const basicShareFacebook = document.querySelector("#basic_share");

if (!!basicShareFacebook) {
    basicShareFacebook.addEventListener("click", () => {
        $.ajax({
            url: "/api/facebook/click/save/basic_results_page",
            type: "POST",
            data: localStorage.getItem("skills"),
            contentType: "application/json",
            dataType: "json",
        });
    });
}

document.querySelectorAll(".extend_share").forEach((el) => {
    el.addEventListener("click", () => {
        $.ajax({
            url: "/api/facebook/click/save/extended_result_page",
            type: "POST",
            data: document.getElementById("extendResultSkills").value,
            contentType: "application/json",
            dataType: "json",
        });
    });
});
